// See https://react-slick.neostack.com/docs/api
export const ImageCarouselSchema = ({ data, schema, intl }) => {
  return {
    fieldsets: [
      {
        id: 'imageCarouselSpotlight',
        title: 'Image Carousel Settings',
        fields: [
          'autoplay',
          'autoplaySpeed',
          'hideNavigationDots',
          'height',
          'itemsPerRow',
        ],
      },
    ],
    properties: {
      autoplay: {
        type: 'boolean',
        title: 'Autoplay',
      },
      autoplaySpeed: {
        type: 'number',
        title: 'Autoplay delay',
        defaultValue: 50,
      },
      hideNavigationDots: {
        type: 'boolean',
        title: 'Hide navigation dots',
      },
      itemsPerRow: {
        type: 'number',
        title: 'Items per row',
        defaultValue: 1,
      },
      height: {
        default: '510px',
        title: (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://developer.mozilla.org/en-US/docs/Web/CSS/height"
          >
            CSS height
          </a>
        ),
      },
    },
  };
};

export const ImageCarouselCardSchema = (args) => {
  return {
    title: 'Image Card',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'attachedimage', 'linkHref'],
      },
    ],

    properties: {
      text: {
        widget: 'slate_richtext',
        title: 'Text',
      },
      attachedimage: {
        widget: 'attachedimage',
        title: 'Image',
      },
      linkHref: {
        widget: 'url',
        title: 'Link',
      },
    },

    required: [],
  };
};

export const LogoCardsSchema = (args) => {
  return {
    title: 'Logo Cards',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'attachedimage', 'linkHref'],
      },
    ],

    properties: {
      title: {
        type: 'string',
        title: 'Title',
        description: 'Text to show on hover',
      },
      attachedimage: {
        widget: 'attachedimage',
        title: 'Image',
      },
      linkHref: {
        widget: 'url',
        title: 'Link',
      },
    },

    required: [],
  };
};

export const SplashyCarouselSchema = (args) => {
  return {
    title: 'Splashy Carousel',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'subTitle',
          'videoUrl',
          'linkHref',
          'linkTitle',
          'attachedimage',
        ],
      },
    ],

    properties: {
      title: {
        type: 'string',
        title: 'Title',
      },
      subTitle: {
        type: 'string',
        title: 'Subtitle',
      },
      videoUrl: {
        widget: 'text',
        title: 'Video URL',
        description: 'Youtube/Vimeo video URL',
      },
      linkTitle: {
        title: 'Button title',
      },
      linkHref: {
        title: 'Call to action',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
      attachedimage: {
        widget: 'attachedimage',
        title: 'Image',
      },
    },

    required: ['attachedimage'],
  };
};

export const VideoCardSchema = (args) => {
  return {
    title: 'Video Card',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'videoUrl', 'linkHref', 'linkTitle', 'attachedimage'],
      },
    ],

    properties: {
      title: {
        type: 'string',
        title: 'Message',
      },
      videoUrl: {
        widget: 'text',
        title: 'Video URL',
        description: 'Youtube/Vimeo video URL',
      },
      linkTitle: {
        title: 'Button title',
      },
      linkHref: {
        title: 'Call to action',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: true,
      },
      attachedimage: {
        widget: 'attachedimage',
        title: 'Preview image',
      },
    },

    required: ['attachedimage'],
  };
};
