import React from 'react';
import { Image, Message, Popup } from 'semantic-ui-react';
import { Placeholder } from 'semantic-ui-react';
import { getScaleUrl, getPath } from '@package/utils';
import { ReactSVG } from 'react-svg';

import './less/logo-cards.less';

export { LogoCardsSchema } from './schema';

const Card = ({ card = {}, height, image_scale, mode = 'view' }) => {
  const { linkHref, title } = card;
  const isSVG = card?.attachedimage?.endsWith('.svg');

  const LinkWrapper =
    linkHref && mode === 'view'
      ? ({ children }) => (
          <a href={linkHref} target="_blank" rel="noreferrer" title={title}>
            {children}
          </a>
        )
      : ({ children }) => children;

  const PopupWrapper = title
    ? ({ children }) => <Popup content={title} trigger={children} on="hover" />
    : ({ children }) => children;

  return (
    <div className="logo-slide-img" style={{ height }}>
      <PopupWrapper>
        <LinkWrapper>
          {card.attachedimage ? (
            <>
              {isSVG ? (
                <ReactSVG
                  src={`${card.attachedimage}/@@images/image/large`}
                  className="svg-wrapper"
                  beforeInjection={(svg) => {
                    svg.setAttribute(
                      'style',
                      `height: ${height}, 'width: auto'`,
                    );
                  }}
                />
              ) : (
                <Image
                  style={{ height: height }}
                  className="bg-image"
                  src={getScaleUrl(
                    getPath(card.attachedimage),
                    image_scale || 'large',
                  )}
                />
              )}
            </>
          ) : (
            <Placeholder />
          )}
        </LinkWrapper>
      </PopupWrapper>
    </div>
  );
};

const LogoCards = (props) => {
  const { data = {}, editable = false } = props;
  const { cards = [], image_scale, height = '80px', title } = data;

  return !cards.length ? (
    editable ? (
      <Message>No cards</Message>
    ) : (
      ''
    )
  ) : (
    <>
      <div>{title}</div>
      <div className="logo-carousel">
        {cards.map((card, i) => (
          <Card
            key={i}
            mode={editable ? 'edit' : 'view'}
            card={card}
            height={height}
            image_scale={image_scale}
          />
        ))}
      </div>
    </>
  );
};

LogoCards.schemaExtender = (schema, data, intl) => {
  return {
    ...schema,
    fieldsets: [
      ...schema.fieldsets,
      {
        id: 'logoCardsSettings',
        title: 'Logo cards settings',
        fields: ['height'],
      },
    ],
    properties: {
      ...schema.properties,
      height: {
        title: (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://developer.mozilla.org/en-US/docs/Web/CSS/height"
          >
            CSS height
          </a>
        ),
        default: '80px',
        description: 'Image max height',
      },
    },
  };
};

export default LogoCards;
