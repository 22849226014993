import SearchOverviewView from './SearchOverviewView';

function SearchOverviewEdit(props) {
  return (
    <>
      <h3>Search Overview block</h3>
      <SearchOverviewView {...props} />
    </>
  );
}
export default SearchOverviewEdit;
